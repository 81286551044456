:root {
  --primary-color: #3e69f6;
  --unselected-color: #70709f;
  --background1: #2b2b42;
  --background2: #2e2e48;
  --background-accent: #33334e;
  --background3: #363655;
  --text-color1: #efefff;
  --text-color2: #9293c5;
  --border-color: #3d3d61;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App {
  display: flex;
  flex-direction: row;
  height: 100dvh;
  width: 100dvw;
  background: black;
  color: var(--text-color1);
}

/*Sidebar*/
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 7%;
  background: var(--background2);
  z-index: 100;
  border-radius: 0 25px 25px 0;

  & .sidebar-logo {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10%;
    width: 7%;
    color: var(--text-color1);
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    cursor: pointer;

    & ion-icon {
      color: var(--primary-color);
      font-size: 3rem;
    }
  }

  & .sidebar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    margin-left: 35%;
    height: 30px;
    width: fit-content;
    color: var(--text-color2);
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 101;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--primary-color);

      & ion-icon {
        box-shadow: -1 -1 60px var(--primary-color);
      }
    }

    &.active {
      color: var(--text-color2);
      background-color: var(--background3);
      border-radius: 25px;
      padding: 20px;
      font-size: 0.8rem;
      margin-left: calc(35% - 25px);
      border: solid 2px var(--border-color);

      & ion-icon {
        color: var(--primary-color);
        font-size: 2rem;
      }
    }
  }
}

/*Dashboard*/
.dashboard {
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: 100%;
  width: 93%;
  margin-left: 7%;

  & .dashboard-main {
    width: 100%;
    height: 100%;
    background: var(--background1);
    background-image: radial-gradient(var(--background-accent) 1px, transparent 0);
    background-size: 10px 10px;
    background-position: -2px -2px;
    border-radius: 25px;

    & .dashboard-main-time {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 25%;
      width: 100%;
      border-radius: 25px 25px 0 0;
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      text-transform: uppercase;

      & .dashboard-main-time-time {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 5rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: var(--text-color1);

        & ion-icon {
          color: var(--primary-color);
          font-size: 6rem;
          position: relative;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      & .dashboard-main-time-date {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: var(--text-color2);
      }
    }

    & .dashboard-main-weather {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 75%;
      width: 100%;
      border-radius: 0 0 25px 25px;

      & h2 {
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: var(--text-color1);
        margin: 0 0 20px 0;
      }

      & .dashboard-main-weather-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        & ion-icon {
          color: var(--primary-color);
          font-size: 4rem;
        }
      }

      & .dashboard-main-weather-temp {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0;
      }

      & .dashboard-main-weather-graph {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }
  }

  & .dashboard-departures {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background: var(--background2);
    border-radius: 25px 0 0 25px;

    & .departures-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 10%;
      width: calc(100% - 50px);
      padding: 0 25px;
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      color: var(--text-color1);

      & ion-icon {
        color: var(--primary-color);
        font-size: 3rem;
        margin-right: 10px;
      }
    }

    & h2 {
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      color: var(--text-color2);
      margin: 0 0 20px 0;
    }

    & .dashboard-departures-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      max-height: 100%;
      width: calc(100% - 50px);
      padding: 0 25px;

      & .dashboard-departures-list-item {
        display: grid;
        grid-template-columns: 20% 10% 55% 15%;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          "icon line destination time"
          "icon name name time";
          grid-gap: 2px;
        width: calc(100% - 50px);
        height: 80px;
        margin: 0 0 30px 0;
        background: var(--background3);
        border-radius: 25px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
        border: 2px solid var(--background3);

        & .dashboard-departures-list-item-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          grid-area: icon;

          & ion-icon {
            color: var(--primary-color);
            font-size: 2rem;
          }
        }

        & .dashboard-departures-list-item-time {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          grid-area: time;
          font-size: 1.2rem;
          font-weight: 600;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          color: var(--primary-color);
        }

        & .dashboard-departures-list-item-line {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          grid-area: line;
          font-size: 1.2rem;
          font-weight: 600;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          color: var(--text-color1);
        }

        & .dashboard-departures-list-item-destination {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          grid-area: destination;
          font-size: 1.2rem;
          font-weight: 600;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          color: var(--text-color1);
        }

        & .dashboard-departures-list-item-name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          grid-area: name;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          color: var(--text-color2);
        }
      }
    }
  }

  & .dashboard-main-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: var(--background1);
    border-radius: 25px;
  }

  & .dashboard-departures-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: var(--background2);
    border-radius: 25px 0 0 25px;
  }
}

/*Weather*/
.weather {
  grid-template-columns: 3fr 2fr;
  height: 100%;
  width: 93%;
  margin-left: 7%;

  & .weather-main {
    display: grid;
    grid-template-columns: 52% 48%;
    grid-template-rows: 55% 45%;
    grid-template-areas:
      "graph graph"
      "rain info";
    height: 100%;
    width: 100%;
    background: var(--background1);
    background-image: radial-gradient(var(--background-accent) 1px, transparent 0);
    background-size: 10px 10px;
    background-position: -2px -2px;
    border-radius: 25px 0 0 25px;

    & .weather-main-temp {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-area: graph;
      height: 100%;
      width: 100%;
      margin: 0;

      & .weather-main-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
        font-size: 3rem;

        & ion-icon {
          color: var(--primary-color);
          font-size: 4rem;
          margin-right: 20px;
        }
      }

      & .weather-main-temp-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
        font-size: 1.2rem;

        & ion-icon {
          color: var(--primary-color);
          font-size: 2rem;
          margin-right: 20px;
        }
      }

      & canvas {
        height: 70% !important;
        width: 100% !important;
      }
    }

    & .weather-main-rain {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-area: rain;
      height: 100%;
      width: 100%;
      margin: 0;

      & .weather-main-rain-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
        font-size: 1.2rem;

        & ion-icon {
          color: var(--primary-color);
          font-size: 2rem;
          margin-right: 20px;
        }
      }

      & canvas {
        height: 80% !important;
        width: 100% !important;
      }
    }
  }

  & .weather-main-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 30%;
    height: 100%;
    width: 100%;
    background: var(--background2);
    border-radius: 25px 25px 0 25px;
    border: var(--border-color);

    & .weather-main-info-time {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--text-color1);

      & .weather-main-info-weather-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;

        & ion-icon {
          color: var(--primary-color);
          font-size: 7rem;
          margin-right: 20px;
        }
      }

      & .weather-main-info-time-time {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 5rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: var(--text-color1);
      }

      & .weather-main-info-time-day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: var(--text-color2);
      }
    }

    & .weather-main-info-weather {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--text-color1);

      & .weather-main-info-weather-description {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: var(--text-color2);
      }
    }
  }

  & .weather-main-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: var(--background1);
    border-radius: 25px 0 0 25px;
  }
}

/*Travel*/
.travel {
  grid-template-columns: 3fr 2fr;
  height: 100%;
  width: 93%;
  margin-left: 7%;

  & .travel-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: var(--background1);
    background-image: radial-gradient(var(--background-accent) 1px, transparent 0);
    background-size: 10px 10px;
    background-position: -2px -2px;
    border-radius: 25px 0 0 25px;

    & .travel-main-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10%;
      width: 100%;
      font-size: 2rem;
      font-weight: 600;
      color: var(--text-color1);
    }

    & .travel-main-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      height: 85%;
      width: 100%;

      & .travel-main-content-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 90%;
        margin: auto;
        background-color: var(--background2);
        border: 1px solid var(--border-color);
        border-radius: 25px;

        & .travel-main-content-item-header {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20%;
          width: 100%;
          font-size: 1.5rem;
          font-weight: 600;
          color: var(--text-color1);

          & ion-icon {
            color: var(--primary-color);
            font-size: 2rem;
            margin-right: 20px;
          }
        }

        & .travel-main-content-item-departure-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          height: 80%;
          width: 100%;
          margin: 0;

          & .travel-main-content-item-departure-list-item {
            display: grid;
            grid-template-columns: 15% 65% 20%;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
              "line destination time"
              "name name time";
              grid-gap: 2px;
            width: calc(100% - 20px);
            height: 80px;
            margin: 0 0 30px 0;
            background: var(--background3);
            border-radius: 25px;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
            border: 2px solid var(--background3);
    
            & .travel-main-content-item-departure-list-item-time {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              grid-area: time;
              font-size: 1.2rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              text-transform: uppercase;
              color: var(--primary-color);
            }
    
            & .travel-main-content-item-departure-list-item-line {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 16px;
              grid-area: line;
              font-size: 1.2rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              text-transform: uppercase;
              color: var(--text-color1);
            }
    
            & .travel-main-content-item-departure-list-item-destination {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              grid-area: destination;
              font-size: 1.2rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              text-transform: uppercase;
              color: var(--text-color1);
            }
    
            & .travel-main-content-item-departure-list-item-name {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 16px;
              grid-area: name;
              font-size: 1rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              text-transform: uppercase;
              color: var(--text-color2);
            }
          }
        }
      }
    }
  }
}

/*News*/
.news {
  grid-template-columns: 3fr 2fr;
  height: 100%;
  width: 93%;
  margin-left: 7%;

  & .news-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background: var(--background1);
    background-image: radial-gradient(var(--background-accent) 1px, transparent 0);
    background-size: 10px 10px;
    background-position: -2px -2px;
    border-radius: 25px 0 0 25px;

    & .news-main-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10%;
      width: 100%;
      font-size: 2rem;
      font-weight: 600;
      color: var(--text-color1);
    }

    & .news-main-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      height: 85%;
      width: calc(100% - 40px);
      overflow-y: scroll;

      & .news-loading {
        grid-column: 1 / 4;
        grid-row: 1 / 3;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: var(--background2);
        border-radius: 25px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--primary-color);
        border-radius: 25px;
      }

      & .news-main-content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        padding-bottom: 5px;
        border-radius: 25px;
        background: var(--background2);
        border: 2px solid var(--border-color);
        text-decoration: none;
        color: var(--text-color1);

        &:first-child {
          grid-column: 1 / 3;
          grid-row: 1 / 3;
          font-size: 2rem;
        }

        & .news-main-content-item-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          aspect-ratio: 16/9;
          margin-bottom: 10px;

          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 25px 25px 0 0;
          }
        }

        & .news-main-content-item-image-missing {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          aspect-ratio: 16/9;
          margin-bottom: 10px;
          border-radius: 25px 25px 0 0;
          background: var(--background3);
          border: 2px solid var(--background3);

          & ion-icon {
            color: var(--primary-color);
            font-size: 4rem;
          }
        }
      }
    }
  }
}

/*About*/
.about {
  grid-template-columns: 2fr 3fr;
  height: 100%;
  width: 93%;
  margin-left: 7%;

  & .about-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background: var(--background1);
    background-image: radial-gradient(var(--background-accent) 1px, transparent 0);
    background-size: 10px 10px;
    background-position: -2px -2px;
    border-radius: 25px 0 0 25px;

    & .about-main-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10%;
      width: 100%;
      font-size: 2rem;
      font-weight: 600;
      color: var(--text-color1);
    }

    & .about-main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 85%;
      width: calc(100% - 40px);
      overflow-y: scroll;
    }
  }
}